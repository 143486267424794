exports.server = {
  port: 8080,
  host: "localhost",

  get serverUrl() {
    //return `http://${this.host}:${this.port}`;

    return `https://studio.icareus.com`;
    // return `https://studiorc.icareus.com`;
  },
  get apiUrl() {
    return `https://studio.icareus.com/api`;
    // return `https://studiorc.icareus.com/api`;

    return `http://${this.host}:${this.port}/api`;
  },
  get IcareusServer() {
    //return `https://suiterc.icareus.com`;
    // return `https://my.icareus.com`;
    // return `http://10.0.10.189`;
    return `http://10.0.10.107`;
  },
};

exports.config = {
  projectPath: "./public/project/WORKER",
  // utf-8 encoding

  declareXML: '<?xml version="1.0" encoding="UTF-8"?>',

  mapFilterNames: {
    fadeInBrightness: "brightness",
    fadeOutBrightness: "brightness",
    fadeInVolume: "volume",
    fadeOutVolume: "volume",
    brightness: "brightness",
  },
};
