import React from "react";
import { Icon } from "@mdi/react";
import { mdiLoading } from "@mdi/js";
import { useTranslation } from "react-i18next";

const mime = require("mime-types");

export default function SourcesTableRow(props) {
  // props.item.mime cause our shapes does not have mime type and we should not include them in source
  const { t } = useTranslation();

  console.log("props", props);

  let { item, projectUrl, adminItems } = props;

  let mimeValue = mime.extension(item.mime);

  console.log(
    adminItems
      ? `/project/WORKER/AdminProjectFiles/${item?.id}/0.jpg`
      : `/project/WORKER/${projectUrl}/${item?.id}/0.jpg`,
    adminItems
  );

  return (
    <>
      {props.item.mime && (
        <tr
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <td
            style={{
              minWidth: "90px",
              marginRight: "10px",
            }}
          >
            <div>
              <i className="material-icons resource-preview" aria-hidden="true">
                {/* {props.item.mime?.includes("image") && "panorama"} */}
                {/* {props.item.mime?.includes("video") && "movie"} */}
                {props.item.mime?.includes("audio") && "audiotrack"}
              </i>

              {props.item.mime?.includes("image") && (
                <img
                  src={
                    adminItems
                      ? `/project/WORKER/AdminProjectFiles/${item?.id}.${mimeValue}`
                      : `/project/WORKER/${projectUrl}/${item?.id}.${mimeValue}`
                  }
                  width={80}
                  height={45}
                  onError={(e) => {
                    e.target.src = "/Image/video_poster.jpeg";
                    // Replace with your fallback image URL
                  }}
                />
              )}

              {props.item.mime?.includes("video") &&
                (props.item?.loading ? (
                  <i
                    className="material-icons resource-preview"
                    aria-hidden="true"
                  >
                    movie
                  </i>
                ) : (
                  <img
                    src={
                      adminItems
                        ? `/project/WORKER/AdminProjectFiles/${item?.id}/0.jpg`
                        : `/project/WORKER/${projectUrl}/${item?.id}/0.jpg`
                    }
                    width={80}
                    height={45}
                    onError={(e) => {
                      e.target.src = "/Image/video_poster.jpeg"; // Replace with your fallback image URL
                    }}
                  />
                ))}
            </div>
          </td>
          <td
            style={{
              flex: 2,
              gap: "0px",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <span
              style={{
                fontWeight: 500,
              }}
            >
              {props.item.name}
            </span>
            {props.item?.composer_name && (
              <span>
                <small>{props.item?.composer_name}</small>
              </span>
            )}
            {props.item.duration !== null && (
              <span>
                <small
                  style={{
                    color: "#0a7aad",
                  }}
                >
                  {props.item.duration}
                </small>
              </span>
            )}
          </td>
          <td className="column-right">
            <>
              {props.item?.loading ? (
                <>
                  <button
                    onClick={() =>
                      props.onInsert(props.item.id, props.adminItems)
                    }
                    disabled
                  >
                    <Icon
                      path={mdiLoading}
                      title="User Profile"
                      size={1}
                      color="white"
                      spin
                      className="material-icons"
                    />{" "}
                  </button>
                </>
              ) : (
                <button
                  onClick={() =>
                    props.onInsert(
                      props.item.id,
                      props.adminItems,
                      props?.musicLibraryresources
                    )
                  }
                >
                  <i className="material-icons" aria-hidden="true">
                    control_point
                  </i>
                </button>
              )}

              {(props.item.mime.includes("video") ||
                props.item.mime.includes("audio")) && (
                <button
                  onClick={() =>
                    props.onPlay(
                      props.item,
                      props.adminItems,
                      props?.musicLibraryresources
                    )
                  }
                >
                  <i className="material-icons" aria-hidden="true">
                    play_arrow
                  </i>
                </button>
              )}
              {!props.adminItems &&
                !props.musicLibraryresources &&
                (props?.item?.loadingDelete ? (
                  <>
                    <>
                      <button
                        onClick={() =>
                          props.onRemove(
                            props.item.id,
                            props.adminItems,
                            props?.musicLibraryresources
                          )
                        }
                        disabled
                      >
                        <Icon
                          path={mdiLoading}
                          title="User Profile"
                          size={1}
                          color="white"
                          spin
                          className="material-icons"
                        />{" "}
                      </button>
                    </>
                  </>
                ) : (
                  <button
                    onClick={() =>
                      props.onRemove(
                        props.item.id,
                        props.adminItems,
                        props?.musicLibraryresources
                      )
                    }
                    className="sources-delete"
                  >
                    <i className="material-icons" aria-hidden="true">
                      delete
                    </i>
                  </button>
                ))}
            </>
          </td>
        </tr>
      )}
    </>
  );
}
