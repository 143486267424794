import React, { useState, useContext } from "react";

export const MyContext = React.createContext();

const StateHolder = (props) => {
	// global available fonts ->this is total available fonts in our machine for now
	const [globalAvailableFonts, setGlobalAvailableFonts] = useState([
		"Roboto",
		"Poppins",
		"Open Sans",
		"Lato",
		"Montserrat",
		"Raleway",
		"Pacifico",
		"Arial",
		"Verdana",
		"Monoton",
		"Gill Sans Nova",
		"Finlandica",
	]);

	// fonts used by current org
	const [globalFonts, setGlobalFonts] = useState([]);

	// global brandColor
	const [globalBrandColor, setGlobalBrandColor] = useState([]);

	// webcamera capture
	const [webcamCapture, setwebcamCapture] = useState(false);
	// screen capture
	const [screenCapture, setscreenCapture] = useState(false);

	const [cropScreenCapture, setCropScreenCapture] = useState(false);
	const [timeline, settimeline] = useState({});

	// project url
	const [projectUrl, setprojectUrl] = useState(null);

	const [pauseCanvas, setPauseCanvas] = useState(false);

	const [time, setTime] = useState(new Date(1970, 0, 1));

	const [updateTimeline, setUpdateTimeline] = useState(false);

	const [timelineLoading, setTimelineLoading] = useState(false);

	const [XMLFiles, setXMLFiles] = useState([]);

	const [isAudioGraphEnabled, setIsAudioGraphEnabled] = useState(false);

	return (
		<MyContext.Provider
			value={{
				globalAvailableFonts,
				setGlobalAvailableFonts,

				globalBrandColor,
				setGlobalBrandColor,

				webcamCapture,
				setwebcamCapture,

				screenCapture,
				setscreenCapture,

				cropScreenCapture,
				setCropScreenCapture,

				timeline,
				settimeline,

				projectUrl,
				setprojectUrl,

				pauseCanvas,
				setPauseCanvas,

				time,
				setTime,

				updateTimeline,
				setUpdateTimeline,

				timelineLoading,
				setTimelineLoading,

				XMLFiles,
				setXMLFiles,

				globalFonts,
				setGlobalFonts,

				isAudioGraphEnabled,
				setIsAudioGraphEnabled,
			}}
		>
			{props.children}
		</MyContext.Provider>
	);
};

export const useMyContext = () => useContext(MyContext);

export default StateHolder;
