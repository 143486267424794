import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";

import { useTranslation } from "react-i18next";

import { server } from "../../config";
import Editor from "./Editor";
import SideNavBar from "./SideNavBar";
import LoadingDialog from "./LoadingDialog";
import SaveModal from "./Modal/SaveModal";
import { mdiFileAccount } from "@mdi/js";
import { useMyContext } from "../contexts/StateHolder";
import ExportModal from "./Modal/ExportModal";
import AssetsModal from "./AssetsFromSuite";

export default function Dashboard(props) {
	const { t } = useTranslation();

	const { projectUrl, setIsAudioGraphEnabled } = useMyContext();

	const [loading, setloading] = useState(false);
	const [dashboardTokenExpiryTime, setdashboardTokenExpiryTime] = useState(null);
	const [showVideoFileModal, setShowVideoFileModal] = useState(false);
	const [videoName, setVideoName] = useState("");
	const [openNew, setOpenNew] = useState(false);
	const [openExist, setOpenExist] = useState(false);

	const updateProject = async (updatedProjectName) => {
		try {
			const url = `${server.apiUrl}/project/${projectUrl}/updateProjectName`;
			const data = { id: projectUrl, projectName: updatedProjectName };
			const res = await axios.post(url, data);
			console.log(res);
		} catch (error) {
			console.log(error);
		}
	};

	const fileUploadSuite = async (e) => {
		try {
			setOpenNew(false);
			setloading(true);
			const url = `${server.apiUrl}/project/${projectUrl}/${videoName}`;
			const data = { finalFile: true, title: videoName };
			setTimeout(() => {
				setloading(false);
				setShowVideoFileModal(false);
				setVideoName("");
				toast.info(`${t("dashboard.videoAvailableSoon")}`);
			}, 4000);
			await axios.put(url, data);
		} catch (error) {
			setloading(false);
			console.log(error);
			// toast.error(`${t("error.serverError")}`);
		}
	};

	const fileUploadSuiteModal = () => {
		setShowVideoFileModal(true);
	};

	useEffect(() => {
		if (dashboardTokenExpiryTime && dashboardTokenExpiryTime !== props.tokenExpiryTime) {
			props.setTokenExpiryTime(dashboardTokenExpiryTime);
		}
	}, [props.tokenExpiryTime, dashboardTokenExpiryTime]);

	useEffect(() => {
		const fetchAccountSetting = async () => {
			const url = `${server.apiUrl}/account-settings`;
			let params = {
				_: Date.now(),
			};

			const res = await axios.get(url, { params });

			setIsAudioGraphEnabled(!!res?.data?.data?.enableStudioAudiographs);
		};
		fetchAccountSetting();
	}, []);

	const handleAssetsAction = async (el) => {
		console.log("el: ", el);
		// TODO: call API to update assset, reference fileUploadSuite function
		const { id, presets, name } = el;
		let asset;
		presets.forEach((preset) => {
			if (preset.name === "master") {
				asset = preset;
			}
		});
		const videoName = Object.values(name)[0];
		const fileId = asset.fileId;
		try {
			setloading(true);
			const url = `${server.apiUrl}/project/${projectUrl}/${videoName}`; // videoName should be present, you can get it from el,
			// here , should be sent assetId, fileId, you can get it from el
			const data = { finalFile: true, title: videoName, isUpdateExist: true, assetId: id, fileId }; // please fileId from master..
			setTimeout(() => {
				setloading(false);
				setOpenExist(false);
				toast.info(`${t("dashboard.videoAvailableSoon")}`);
			}, 4000);
			const res = await axios.put(url, data);
			console.log(res.data);
		} catch (error) {
			setloading(false);
			toast.error(`${t("error.serverError")}`);
		}
	};

	return (
		<>
			{showVideoFileModal && (
				<ExportModal
					setOpen={setShowVideoFileModal}
					setOpenNew={setOpenNew}
					setOpenExist={setOpenExist}
				/>
			)}
			{openExist && (
				<AssetsModal
					onAssetModalHandler={setOpenExist}
					buttonLabel="update"
					buttonAction={handleAssetsAction}
				/>
			)}
			{openNew && (
				<SaveModal
					inputValue={videoName}
					setInputValue={setVideoName}
					inputLabel={t("dashboard.enterFileName")}
					confirmHandler={fileUploadSuite}
					cancelHandler={() => setOpenNew(false)}
				/>
			)}
			{props.showTokenModal && (
				<SaveModal
					showOnlyTextMessage={true}
					confirmIcon={mdiFileAccount}
					confirmHandler={props.extendSession}
					cancelHandler={props.continueSession}
					textMessage={t("error.sessionTimeoutMessage")}
					inputLabel={t("newProjectDialog.sessionTimeout")}
					confirmButtonText={t("newProjectDialog.extendSession")}
				/>
			)}

			{loading && <LoadingDialog heading="Uploading File" />}
			<div className="dashboard">
				<div className="sideBar">
					{" "}
					<SideNavBar fileUploadSuiteModal={fileUploadSuiteModal} updateProject={updateProject} />
				</div>
				<div className="editor">
					{" "}
					<Editor
						dashboardTokenExpiryTime={dashboardTokenExpiryTime}
						setdashboardTokenExpiryTime={setdashboardTokenExpiryTime}
					/>
				</div>
			</div>
		</>
	);
}
