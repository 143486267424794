import React, { useCallback, useEffect, useRef, useState } from "react";
import { Timeline as Vis } from "vis-timeline/standalone";
import axios from "axios";
import date from "date-and-time";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "@mdi/react";
import { toast } from "react-toastify";
import { mdiScissorsCutting, mdiShape, mdiText, mdiTrashCan } from "@mdi/js";
import { useTranslation } from "react-i18next";

import AddText from "./Filter/AddText";
import ShowAllFilterModal from "./Filter/ShowAllFilterModal";
import TextModal from "./Filter/TextModal";
import ShapesModal from "./Modal/ShapesModal";
import TimelineModel from "./TimelineModel";
import AddFilterDialog from "./AddFilterDialog";
import timeManager from "../../models/timeManager";
import { server } from "../../config";
import keycharm from "keycharm";

import {
	manageImageYPosition,
	splitTime,
	manageImageXPosition,
	videoTrack1Template,
	videoTrack1TemplateWithoutString,
	videoTrack2Template,
	addFilterTemplate,
	videoBlankTrack0Item,
	videoTrack0Items,
	audioTrackItems,
	audioBlankTrackItem,
} from "../shared/utils";
import ImageModal from "./Modal/ImageModal";
import TransitionModal from "./Modal/TransitionModal";
import { useMyContext } from "../contexts/StateHolder";
import {
	MdZoomIn,
	MdZoomOut,
	MdKeyboardArrowRight,
	MdKeyboardArrowLeft,
	MdDeleteOutline,
	MdOutlineFullscreenExit,
} from "react-icons/md";
import { FaUndoAlt } from "react-icons/fa";
import EffectsModal from "./Modal/EffectsModal";
import DeleteModal from "./Modal/DeleteModal";
import WFPlayer from "wfplayer";

const mime = require("mime-types");
var rangeInclusive = require("range-inclusive");

let durationValue = "00:00:00,000";

function useForceUpdate() {
	const [value, setValue] = useState(0); // integer state
	return () => setValue((value) => value + 1); // update the state to force render
}
let wfPlayers = {};
export default function Timeline(props) {
	const { t } = useTranslation();
	const { loading: mainCompLoading } = props;
	let isMoving = false;
	let timeline = null;
	let app = {};
	let cloneAllAssetFilter;

	const forceUpdate = useForceUpdate();

	const {
		time,
		setPauseCanvas,
		setGlobalBrandColor,
		globalBrandColor,
		projectUrl,
		updateTimeline,
		setUpdateTimeline,
		setTimelineLoading,
		XMLFiles,
		setXMLFiles,
		isAudioGraphEnabled,
	} = useMyContext();

	const [selectedItems, setselectedItems] = useState([]);
	const [initialUseEffect, setinitialUseEffect] = useState(false);
	const [runOnce, setrunOnce] = useState(true);
	const [loadingDelete, setLoadingDelete] = useState(false);
	// show image modal
	const [showImageModal, setShowImageModal] = useState(false);
	const [showShapesModal, setShowShapesModal] = useState(false);
	const [editShapeModal, setEditShapeModal] = useState(false);
	const [imageDuration, setImageDuration] = useState("");
	// show al effets modal
	const [showAllEffectsModal, setShowAllEffectsModal] = useState(false);
	const [loading, setLoading] = useState(false);
	// send filter name to FilterDialog
	const [filterName, setfilterName] = useState(null);
	const [openAddFilterDialogEdit, setOpenAddFilterDialogEdit] = useState(false);
	const [showAllFilterModal, setShowAllFilterModal] = useState(false);
	const [addTextClicked, setAddTextClicked] = useState(false);
	// whenever we click the text asset we save the innfo here and then pass to TextModal
	const [textModalInfo, setTextModalInfo] = useState(null);
	// storing index to pass into AddText moda via TextModal
	const [deleteTextIndex, setDeleteTextIndex] = useState(null);
	// show  different transtion modal
	const [showTransitionModal, setShowTransitionModal] = useState(false);
	const [shapeDuration, setShapeDuration] = useState("");
	// use this when deleting from ICON above(near the timeline)
	const [showConirmDeleteModal, setShowConirmDeleteModal] = useState(false);
	const [timelineLoaded, setTimelineLoaded] = useState(false);
	const [audioItems, setAudioItems] = useState([]);

	const currentTimelineItems = useRef();
	const ref = useRef();
	const timelineRef = useRef();
	const allTextFilterRef = useRef();

	const timelineButtonStyle = {
		marginRight: "5px",
		width: "24px",
		height: "24px",
	};

	const timelineButtons = [
		{
			icon: <FaUndoAlt style={{ ...timelineButtonStyle, width: "20px" }} />,
			onClick: () => UNDOProject(),
			id: 7,
			disabled: XMLFiles?.length === 0 ? true : false,
		},
		{
			icon: <MdOutlineFullscreenExit style={timelineButtonStyle} />,
			onClick: () => {
				timelineRef.current.fit(timelineRef.current.getVisibleItems());
			},
			id: 5,
		},
		{
			icon: <MdZoomOut style={timelineButtonStyle} />,
			onClick: () => timelineRef.current.zoomOut(0.5),
			id: 1,
		},
		{
			icon: <MdZoomIn style={timelineButtonStyle} />,
			onClick: () => {
				const allItems = timelineRef.current
					.getItemsAtCurrentTime(time)
					.find((el) => el.includes("videotrack0") || el.includes("blankvidtrack"));
				let seekedvideoitem;
				seekedvideoitem = timelineRef.current.itemsData.get().find((el) => el.id == allItems);
				if (seekedvideoitem) {
					return timelineRef.current.setWindow(seekedvideoitem?.start, seekedvideoitem?.end);
				} else {
					timelineRef.current.zoomIn(0.5);
				}
			},
			id: 2,
		},
		{
			icon: <MdKeyboardArrowLeft style={timelineButtonStyle} />,
			onClick: () => moveTimelineItem(0.6),
			id: 3,
		},
		{
			icon: <MdKeyboardArrowRight style={timelineButtonStyle} />,
			onClick: () => moveTimelineItem(-0.6),
			id: 4,
		},
		{
			icon: <MdDeleteOutline style={timelineButtonStyle} />,
			onClick: () => {
				if (!selectedItems[0]) {
					return toast.error(`${t("timeline.selectAnyItemfromTimeline")}`, {
						position: "top-left",
						autoClose: 2000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: false,
						theme: "light",
					});
				}
				let item = {};

				item.id = selectedItems[0];
				showConfirmModalHandler();
				// runFunction(item);
			},
			id: 6,
		},
	];

	const getPlayerContainerId = (item) => `player-${item.id}`;

	useEffect(() => {
		ref.current = {};

		if (props.checkProps && runOnce && !timeline) {
			const container = document.getElementById("timeline");
			let audiotrackDuration = Object.values(props.items.audio)
				.map((el) => el?.duration)
				.sort((a, b) => a * 1 - b * 1)[0];
			// it includes all shapes  image and video duration
			let videoTrackDuration = Object.values(props.items.video)
				.map((el) => el?.duration)
				.sort((a, b) => a * 1 - b * 1)[0];
			// we are adding 1/2 hour more duration than items timeline length
			let maxDuration = timeManager.addDuration(
				"00:30:00,000",
				audiotrackDuration > videoTrackDuration ? audiotrackDuration : videoTrackDuration
			);

			const options = {
				orientation: "top",
				min: new Date(1970, 0, 1),
				max: TimelineModel.dateFromString(maxDuration),
				showCurrentTime: false,
				multiselect: false,
				multiselectPerGroup: true,
				stack: false,
				stackSubgroups: false,
				// zoom will ony work when pressed shitKey in timeline
				zoomKey: "shiftKey",
				// cannot be zoomed more than 1 second
				zoomMin: 10000,
				// not more than 5 hour
				zoomMax: 30000000,
				verticalScroll: true,
				horizontalScroll: true,
				autoResize: true,
				tooltip: {
					followMouse: true,
					overflowMethod: "flip",
				},
				editable: {
					updateTime: true,
					updateGroup: true,
					remove: true,
				},
				onMove: onMove,
				onMoving: onMoving,

				format: {
					minorLabels: {
						millisecond: "SSS [ms]",
						second: "s [s]",
						minute: "HH:mm:ss",
						hour: "HH:mm:ss",
						weekday: "HH:mm:ss",
						day: "HH:mm:ss",
						week: "HH:mm:ss",
						month: "HH:mm:ss",
						year: "HH:mm:ss",
					},
					majorLabels: {
						millisecond: "HH:mm:ss",
						second: "HH:mm:ss",
						minute: "",
						hour: "",
						weekday: "",
						day: "",
						week: "",
						month: "",
						year: "",
					},
				},
				template: function (item) {
					if (item.group === "videotrack1") {
						if (typeof item.content === "string") {
							let mimeValueArray = item.content.split(".").length;
							let mimeValue = item.content.split(".")[mimeValueArray - 1];

							let src;
							if (item?.item?.resource_s3) {
								src = item?.item?.resource_s3;
							} else {
								src = item.item.admin
									? `/project/WORKER/AdminProjectFiles/${item.contentID}.${mimeValue}`
									: `/project/WORKER/${projectUrl}/${item.contentID}.${mimeValue}`;
							}
							return videoTrack1Template(item, src, showConfirmModalHandler);
						} else {
							// it might not even reeach here
							return videoTrack1TemplateWithoutString(item, showConfirmModalHandler);
						}
					} else {
						const div = document.createElement("div");
						div.className = "vis-item-content__customdiv";

						if (item.group === "videotrack2") {
							videoTrack2Template(item, div);
						} else if (item.group.includes("audiotrack")) {
							div.id = getPlayerContainerId(item);
						} else if (item.group.includes("videotrack0")) {
							div.id = getPlayerContainerId(item);
							div.style = "margin-bottom: 31px;";
							div.innerHTML = item.content;
						} else {
							div.innerHTML = item.content;
						}

						return addFilterTemplate(div, item, openSelectedFilter, showConfirmModalHandler);
					}
				},
			};

			timeline = new Vis(container, [], [], options);

			timeline.addCustomTime(0);
			timeline.setCustomTimeTitle("00:00:00,000");
			timeline.setCustomTimeMarker("");
			timeline.on("select", onSelect);

			timeline.on("timechanged", onTimeChanged);
			timeline.on("moving", onMoving);
			timeline.on("move", onMove);
			timeline.on("doubleClick", doubleClick);

			timelineRef.current = timeline;
			props.settimelineref(timeline);

			ref.current = props.items;
			setinitialUseEffect(true);

			setrunOnce(false);
		}
	}, [
		props.checkProps,
		timelineRef.current,
		timeline,
		JSON.stringify(props.allAssetFilter),
		props.allAssetFilter,
		JSON.stringify(props.items),
	]);

	useEffect(() => {
		if (updateTimeline) {
			updateTimelineTemplate();
		}
	}, [updateTimeline]);

	useEffect(() => {
		const runSecondEffect = async () => {
			if (initialUseEffect) {
				const runFunction = async () => {
					timeline = timelineRef.current;

					timeline.setCustomTime(time);
					timeline.setCustomTimeTitle(TimelineModel.dateToString(time));

					if (document.querySelector(".vis-custom-time")) {
						const t = document.querySelector(".vis-custom-time");
						const newSpan = document.createElement("span");
						newSpan.id = "newSpan";
						newSpan.classList.add("toolTipText");
						newSpan.innerText = TimelineModel.dateToString(time);
						t.append(newSpan);
					}
					if (
						JSON.stringify(ref.current) === JSON.stringify(props.items) &&
						JSON.stringify(allTextFilterRef.current) === JSON.stringify(props.allAssetFilter)
					)
						return;
					setTimelineLoading(true);

					const groups = [];
					const items = [];
					let clonePropsItemsVideo = [...props.items.video];

					clonePropsItemsVideo = [
						clonePropsItemsVideo[2],
						clonePropsItemsVideo[1],
						clonePropsItemsVideo[0],
					];

					const tracks = [...clonePropsItemsVideo, ...props.items.audio];
					// we get info of all the tracks for instance videotrack0 and videotrack1
					// in the videitrack0 (default track) if we have 3 videos and in the videotrack1 we hvae 0 video we map over them and put it and assign in group
					const videoMatch = new RegExp(/^videotrack\d+/);
					const exactVideoMatch = new RegExp(/^videotrack0$/);
					// checking for image cause we are putting image in videotrack1
					const imageMatch = new RegExp(/^videotrack1$/);
					const audioMatch = new RegExp(/^audiotrack0$/);
					const audioMatch1 = new RegExp(/^audiotrack1$/);
					const shapeMatch = new RegExp(/^videotrack2$/);

					// now we have videotrack0 which have 3 videos we map over them and push id in each track not tracks
					let contentName;
					if (props.allAssetFilter) {
						cloneAllAssetFilter = [...props.allAssetFilter];
						const groupDiv = document.createElement("div");
						groupDiv.innerHTML = "Text";
						const p = document.createElement("p");

						groupDiv.append(p);
						cloneAllAssetFilter.map((el, i) => {
							groups.push({
								id: `TextTrack${i}`,
								content: groupDiv.innerHTML,
							});
						});

						cloneAllAssetFilter.map((el, i) => {
							Object.values(el).forEach((el1) => {
								app[el1["name"]] = el1["value"];
							});

							return items.push({
								id: "TextTrack" + ":" + i,
								content: app.argument,
								start: TimelineModel.dateFromString(app.in),
								end: TimelineModel.dateFromString(app.out),
								className: "Text",
								group: `TextTrack${i}`,
								previousGroup: `TextTrack${i}`,
								title: `${t("timeline.doubleClickForDetails")}`,
								el,
							});
						});
					}

					for (let track of tracks) {
						let labelClassName = "";
						if (exactVideoMatch.test(track.id)) {
							contentName = `${t("timeline.video")}`;
							labelClassName = "vis-label-video";
						} else if (imageMatch.test(track.id)) {
							contentName = `${t("timeline.image")}`;
						} else if (audioMatch.test(track.id)) {
							contentName = `${t("timeline.audio")}1`;
							labelClassName = "vis-label-audio";
						} else if (audioMatch1.test(track.id)) {
							contentName = `${t("timeline.audio")}2`;
							labelClassName = "vis-label-audio";
						} else if (shapeMatch.test(track.id)) {
							contentName = `${t("timeline.shape")}`;
						} else {
							contentName = null;
						}
						const divGroup = document.createElement("div");

						divGroup.className = "timeline-custom-div";
						divGroup.innerHTML = contentName;

						divGroup.style.color = "#e1e1e1";
						divGroup.style.fontSize = "13px";

						groups.push({
							id: track.id,
							content: divGroup,
							className: labelClassName,
						});
						// now out of each track we map over them(which has property called items which is an array of all store video say 3 videos)we map over all 3 videos and put id and other stuff
						// ma
						// console.log('DEBUG timeline, track.items', track.items, track.id);
						// here we have to check if the last item of the video end duration is more than both the audio tracks
						const audio1trackEndDuration = props.items.audio[0].duration;
						const audio2trackEndDuration = props.items.audio[1].duration;
						const highestAudioTrackDuration =
							TimelineModel.dateFromString(audio1trackEndDuration) >
							TimelineModel.dateFromString(audio2trackEndDuration)
								? audio1trackEndDuration
								: audio2trackEndDuration;

						// if video duration is less than image or shape or text duration, add blank video
						const highestVideoTrackDuration =
							TimelineModel.dateFromString(props.items.video[1].duration) >
							TimelineModel.dateFromString(props.items.video[2].duration)
								? props.items.video[1].duration
								: props.items.video[2].duration;

						const highestDuration =
							TimelineModel.dateFromString(highestVideoTrackDuration) >
							TimelineModel.dateFromString(highestAudioTrackDuration)
								? highestVideoTrackDuration
								: highestAudioTrackDuration;
						// there is no one video clip, should add blank video
						if (track.id.includes("videotrack0") && track.items.length === 0) {
							videoBlankTrack0Item(
								track,
								0,
								null,
								items,
								TimelineModel.dateFromString("00:00:00,000"),
								TimelineModel.dateFromString(highestDuration),
								null,
								highestDuration,
								null
							);
						}
						await Promise.all(
							track.items.map(async (item, index) => {
								if (!props.resources[item.resource]) {
									props.loadData(true);
								}
								let content = props.resources[item.resource]?.name;
								let content1 = props.resources[item.resource]?.id;

								let className = videoMatch.test(track.id) ? "video" : "audio";

								let contentDimImage = document.createElement("div");

								contentDimImage.className = "timeline-custom-div";
								if (contentName !== `${t("timeline.video")}`) {
									contentDimImage.innerHTML = content;
								}
								if (contentName === `${t("timeline.video")}`) {
									// original  videoLength in second
									const originalVideoLength = splitTime(props?.resources[item?.resource]?.duration);

									// total Video length in timeline
									const totalVideoLengthVideotrack1 = splitTime(props.items.video[0].duration);

									// in and out of current videolength
									const inn = splitTime(item.in);
									const out = splitTime(item.out);

									const currentTotalVideoLength = out - inn;
									// will return false if not integer
									// const isInt = (n) => {
									//     return n % 1 === 0;
									// };
									const insertImageInRange = (differences, innValue, outValue, Value) => {
										totalImageToBeInserted = differences;
										result = rangeInclusive(innValue, outValue, Value).map((el) =>
											Math.floor(el / Value)
										);
										result = Object.values(result).filter((el) => el < outValue / Value);
									};

									const insertOneImage = (innValue, Value) => {
										result = [Math.floor(innValue / 5)];
									};
									const insertTwoImage = (innValue, outValue, Value) => {
										result = [Math.floor(innValue / Value), Math.floor(outValue - Value / Value)];
									};
									const insertImagesInBetween = (Value, totalImageToBeInsertedValue) => {
										let outFinalValue;
										if (originalVideoLength <= 180) {
											outFinalValue = 0.5;
										} else if (originalVideoLength > 180 && originalVideoLength < 600) {
											outFinalValue = 1;
										} else if (originalVideoLength >= 600) {
											outFinalValue = 3;
										}

										// this is the number of image available in that video asseet
										const totalNumberOfAvailableImages = Math.floor((out - inn) / outFinalValue);

										// totalImageToBeInsertedValue means this is the number that can be inserted in
										let newImagesLength = Math.ceil(
											totalNumberOfAvailableImages / Math.ceil(totalImageToBeInsertedValue)
										);

										result = rangeInclusive(inn * Value, out * Value, newImagesLength);
										result = result.filter((el) => el * 1 >= inn * Value && el * 1 <= out * Value);
									};
									// now we will map over the images based on start and end. We have our duration of images divided into following cases
									/* 
                                        Case1 : Video is less than 15 second N=[1,2...,N]
                                        Case2 : Video is greater than 15 and less  than 60 second N=[1,2...,5]
                                        Case3 : Video is greater than 60 and less  than 180 second N=[1,2...,5]

                                        Case4 : Video is greater than 60 and less  than 599 second (number =Numer of Minutes/2) N=[1,...number]
                                        Case4 : Video is greater than 600 (number =Numer of minutes) N=[1,...number]
                                    */

									// percentage of current video in timeline
									let currentVideoPercentInTimeline =
										currentTotalVideoLength / totalVideoLengthVideotrack1;
									// totalTimelineWidth= window.innerWidth-sidebarWidth(90)-group(ex: Video,text:approx :80-90)- 20(misc) so 190
									let totalTimelineWidth = window.innerWidth - 190;

									// this below should be approx length of currentVideoPixelInTimeline i.e current item in timeline
									let currentVideoPixelInTimeline =
										currentVideoPercentInTimeline * totalTimelineWidth;

									// ~~ removes decimal 96 is width of each image
									let totalImageToBeInserted = currentVideoPixelInTimeline / 96;

									// let checkIfFloatingPoint = isInt(totalImageToBeInserted);

									if (totalImageToBeInserted == 0) totalImageToBeInserted = 1;
									let endStartDifference;

									let result = [];
									// case1 Video is less than 180 second
									if (originalVideoLength <= 180) {
										// frame is generrated every 0.5 seond
										endStartDifference = Math.floor((out - inn) / 0.5);
										if (totalImageToBeInserted >= endStartDifference) {
											// multiplied by 2 cause it is generated 2 times in 1 second
											insertImageInRange(endStartDifference, inn * 2, out * 2 + 1, 1);
										} else {
											if (totalImageToBeInserted === 1) {
												insertOneImage(inn, 0.5);
											} else if (totalImageToBeInserted === 2) {
												insertTwoImage(inn, out, 0.5);
											} else {
												insertImagesInBetween(2, totalImageToBeInserted);
											}
										}
									}

									// case 2 Video is greater than 180 and less  than 600 second N=[1,2...,5]
									else if (originalVideoLength > 180 && originalVideoLength < 600) {
										// frame is generrated every 5 seond
										endStartDifference = Math.floor((out - inn) / 1);

										if (totalImageToBeInserted >= endStartDifference) {
											insertImageInRange(endStartDifference, inn, out + 1, 1);
										} else {
											if (totalImageToBeInserted === 1) {
												insertOneImage(inn, 1);
											} else if (totalImageToBeInserted === 2) {
												insertTwoImage(inn, out, 1);
											} else {
												insertImagesInBetween(1, totalImageToBeInserted);
											}
										}
									}

									// case 4 Video is greater than 60  and less  than 599
									else if (originalVideoLength >= 600) {
										// frame is generrated every 15 seond
										endStartDifference = (out - inn) / 3;
										endStartDifference = Math.floor(endStartDifference);

										if (totalImageToBeInserted >= endStartDifference) {
											insertImageInRange(
												endStartDifference,
												Math.ceil(inn / 3),
												Math.ceil(out / 3),
												3
											);
										} else {
											if (totalImageToBeInserted === 1) {
												insertOneImage(inn, 3);
											} else if (totalImageToBeInserted === 2) {
												insertTwoImage(inn, out, 3);
											} else {
												insertImagesInBetween(1 / 3, totalImageToBeInserted);
											}
										}
									}

									const runSecondFunction = () => {
										// if the video is very small to be inserted, we will just put one image
										// taking unique value
										result = [...new Set(Object.values(result))];
										if (result.length === 0) {
											result = [1];
										}
										Object.values(result).map((el, i) => {
											let img = new Image();
											img.height = 55;

											img.style.width = window.innerWidth > 1440 ? "140px" : "110px";

											if (props.items.video[0]?.items.length === 1) {
												img.style.width = window.innerWidth > 1440 ? "150px" : "120px";
											}
											if (
												endStartDifference > totalImageToBeInserted &&
												result.length + 1 >= totalImageToBeInserted
											) {
												img.style.width = window.innerWidth > 1440 ? "120px" : "100px";
											}
											// if (i === 0 && !checkIfFloatingPoint) {
											//     // calculating the remaining px value of floating point  example if totalImagesTobeInserted is 9.94 our s will change to 10 . so 10*94 is 940 and we will minus from curreentVideoPiselInTimeline
											//     let s = Math.ceil(totalImageToBeInserted);
											//     let g = Math.abs(s * 96 - currentVideoPixelInTimeline);

											//     if (props.items.video[0]?.items.length > 1) {
											//         // img.style.marginLeft = `${g}px`;
											//     }
											// }

											img.src = props.resources[item.resource]?.admin
												? `/project/WORKER/AdminProjectFiles/${
														props.resources[item.resource]?.id
												  }/${Math.floor(el)}.jpg `
												: `/project/WORKER/${projectUrl}/${
														props.resources[item?.resource]?.id
												  }/${Math.floor(el)}.jpg`;

											img.alt = "Loading...";

											img.onerror = function () {
												console.log("Error loading img: " + img.src);
												let newImage = document.createElement("img");
												newImage.src =
													"https://flixier-staging.s3-eu-west-1.amazonaws.com/transitions/Burn.png";
												contentDimImage.appendChild(newImage);
											};

											contentDimImage.appendChild(img);
										});
									};
									runSecondFunction();
								}

								// adding blank items for video which is not displayed to user
								if (track.id.includes("videotrack0")) {
									// lets find the source and everything here
									let f = Object.values(item?.admin ? props.adminItems : props.resources).find(
										(el) => el.id === item.resource
									);

									let mimeValue = mime.extension(f?.mime);
									if (mimeValue === "qt") {
										mimeValue = "mov";
									}
									let url;

									if (item?.resource_s3_preset) {
										url = `${item?.resource_s3_preset}#t=${splitTime(item.in)},${splitTime(
											item.out
										)}`;
									} else {
										if (item?.resource_s3) {
											url = `${item?.resource_s3}#t=${splitTime(item.in)},${splitTime(item.out)}`;
										} else {
											url = `WORKER/${f?.admin ? "AdminProjectFiles" : projectUrl}/${
												f.id
											}.${mimeValue}#t=${splitTime(item.in)},${splitTime(item.out)}`;
										}
									}

									let videoTrackParams = {
										track,
										index,
										contentDimImage,
										className,
										content1,
										url,
										title: `${t("timeline.viewEffectDetailsModal")}`,
									};

									if (index > 0 && index !== track.items.length - 1) {
										if (item.start !== track.items[index - 1].end) {
											videoBlankTrack0Item(track, index, item, items);
										}

										videoTrack0Items(item, items, videoTrackParams);
									} else if (index === 0) {
										// need to check if first element is blank
										if (splitTime(item.start) !== 0) {
											// we have a blank item here which starts from 0( timleine)
											// here we are passing al params cause this is the first item f timeline and we need out from 2nd item and so on
											videoBlankTrack0Item(
												track,
												index,
												item,
												items,
												TimelineModel.dateFromString("00:00:00,000"),
												TimelineModel.dateFromString(item.start),
												"00:00:00,000",
												item.start
											);
										}

										videoTrack0Items(item, items, videoTrackParams);

										// if there is only one video, then should be checked for blank video can be added or not
										if (index === track.items.length - 1) {
											if (
												TimelineModel.dateFromString(item.end) <
												TimelineModel.dateFromString(highestDuration)
											) {
												// there is audio item and duration is more than videotrack duration
												// here we are passing al params cause this is the first item f timeline and we need out from 2nd item and so on
												videoBlankTrack0Item(
													track,
													index + 1,
													item,
													items,
													TimelineModel.dateFromString(item.end),
													TimelineModel.dateFromString(highestDuration),
													item.end,
													highestDuration,
													splitTime(item.end)
												);
											}
										}
									} else if (index === track.items.length - 1 && index !== 0) {
										// we also have to check if this element has blank infront of it
										if (item.start !== track.items[index - 1].end) {
											videoBlankTrack0Item(track, index, item, items);
										}
										videoTrack0Items(item, items, videoTrackParams);

										if (
											TimelineModel.dateFromString(item.end) <
											TimelineModel.dateFromString(highestDuration)
										) {
											// there is audio item and duration is more than videotrack duration
											// here we are passing al params cause this is the first item f timeline and we need out from 2nd item and so on
											videoBlankTrack0Item(
												track,
												index + 1,
												item,
												items,
												TimelineModel.dateFromString(item.end),
												TimelineModel.dateFromString(highestDuration),
												item.end,
												highestDuration,
												splitTime(item.end)
											);
										}
									}
								} else if (track.id.includes("audiotrack")) {
									let f;
									if (item?.musicLibraryFile) {
										f = Object.values(props.musicLibraryresources).find(
											(el) => el.id === item.resource
										);
									} else if (item?.admin) {
										f = Object.values(props.adminItems).find((el) => el.id === item.resource);
									} else {
										f = Object.values(props.resources).find((el) => el.id === item.resource);
									}

									let mimeValue = mime.extension(f?.mime);
									if (mimeValue === "mpga") {
										mimeValue = "mp3";
									}

									let url;

									if (f?.resource_s3) {
										url = `${f?.resource_s3}#t=${splitTime(item.in)},${splitTime(item.out)}`;
									} else if (f?.musicLibraryFile) {
										url = `WORKER/AdminProjectFiles/MusicLibraryFiles/${
											f.id
										}.${mimeValue}#t=${splitTime(item.in)},${splitTime(item.out)}`;
									} else if (f?.admin) {
										if (f?.id) {
											url = `WORKER/AdminProjectFiles/${f.id}.${mimeValue}#t=${splitTime(
												item.in
											)},${splitTime(item.out)}`;
										}
									} else {
										url = `WORKER/${projectUrl}/${f.id}.${mimeValue}#t=${splitTime(
											item.in
										)},${splitTime(item.out)}`;
									}
									let audioTrackParams = {
										track,
										index,
										contentDimImage,
										className,
										content1,
										url,
										title: `${t("timeline.viewEffectDetailsModal")}`,
									};
									if (index > 0) {
										if (item.start !== track.items[index - 1].end) {
											audioBlankTrackItem(
												track,
												index,
												item,
												items,
												TimelineModel.dateFromString(track.items[index - 1].end),
												TimelineModel.dateFromString(item.start),
												track.items[index - 1].end,
												item.start,
												splitTime(track.items[index - 1].end),
												splitTime(item.start)
											);
										}

										audioTrackItems(item, items, audioTrackParams);
									} else if (index === 0) {
										if (splitTime(item.start) !== 0) {
											audioBlankTrackItem(
												track,
												index,
												item,
												items,
												TimelineModel.dateFromString("00:00:00,000"),
												TimelineModel.dateFromString(item.start),
												"00:00:00,000",
												item.start,
												0,
												splitTime(item.start)
											);
										}

										audioTrackItems(item, items, audioTrackParams);
									}
								}

								if (!track.id.includes("videotrack0") && !track.id.includes("audiotrack"))
									items.push({
										id: track.id + ":" + index,
										content: contentDimImage.innerHTML,
										start: TimelineModel.dateFromString(item.start),
										end: TimelineModel.dateFromString(item.end),
										group: track.id,
										previousGroup: track.id,

										className: className,
										contentID: content1,
										title: `${t("timeline.viewEffectDetailsModal")}`,
										item,
									});
							})
						);

						if (track.duration > durationValue) {
							durationValue = track.duration;
						} else if (track.duration < durationValue && track.duration != "00:00:00,000") {
							if (!track.id.includes("audiotrack")) {
								durationValue = track.duration;
							}
						}
					}

					if (props.duration !== durationValue) {
						props.setduration(durationValue);
					}

					// setting the data to the screen which will visible to the end user
					await timeline.setData({
						items: items,
						groups: groups,
					});
					props.settimelineref(timeline);

					// TODO: mohit
					const tempItems = items.filter(
						(x) => x.id.includes("audiotrack") || x.id.includes("videotrack0")
					);

					setAudioItems(tempItems);
					timelineRef.current = timeline;

					!timelineLoaded && timeline.fit(timeline.getVisibleItems());

					setTimeout(() => {
						setTimelineLoading(false);
					}, 20);
					setTimelineLoaded(true);
				};

				await runFunction();
			}
			ref.current = props.items;

			allTextFilterRef.current = props.allAssetFilter;
		};
		runSecondEffect();
	}, [
		durationValue,
		props,
		JSON.stringify(props),
		initialUseEffect,
		props.item,
		time,
		JSON.stringify(props.allAssetFilter),
		cloneAllAssetFilter,
		JSON.stringify(props.item),
		props.items,
		JSON.stringify(props.items),
	]);

	useEffect(() => {
		if (props.items) {
			setLoading(true);
			setTimeout(() => {
				currentTimelineItems.current = props.items;
				setLoading(false);
			}, 100);
		}
	}, [JSON.stringify(props.items), mainCompLoading, setLoading]);

	// deleting item on deleete button pressed
	let keys = keycharm([{ container: document.getElementById("timeline") }]);
	keys.bind(
		"delete",
		function () {
			if (!selectedItems[0]) {
				return;
			}
			let item = {};

			item.id = selectedItems[0];
			showConfirmModalHandler();
		},
		"keydown"
	);

	const UNDOProject = async () => {
		const data = {
			xmlFileContent: XMLFiles[XMLFiles?.length - 1],
		};

		const url = `${server.apiUrl}/project/${projectUrl}/redo`;
		const res = await axios.post(url, data);
		if (res.data?.msg === "success") {
			// lets remov the last item from array
			if (XMLFiles?.length > 0) {
				let newContent = [...XMLFiles];
				newContent.pop();
				setXMLFiles(newContent);
			}
			props.loadData(true);
		}
	};

	const showConfirmModalHandler = () => {
		setShowConirmDeleteModal(true);
	};

	const checkForSelectedItemToastError = () => {
		toast.error(`${t("timeline.selectAnyItemfromTimeline")}`, {
			position: "top-center",
			hideProgressBar: true,
			closeOnClick: true,
			autoClose: 2000,
		});
	};

	const updateTimelineTemplate = useCallback(() => {
		let audiotrackDuration = Object.values(props.items.audio)
			.map((el) => el?.duration)
			.sort((a, b) => a * 1 - b * 1)[0];
		// it includes all shapes  image and video duration
		let videoTrackDuration = Object.values(props.items.video)
			.map((el) => el?.duration)
			.sort((a, b) => a * 1 - b * 1)[0];

		let maxDuration = timeManager.addDuration(
			"00:30:00,000",
			audiotrackDuration > videoTrackDuration ? audiotrackDuration : videoTrackDuration
		);

		timelineRef.current.setOptions({
			max: TimelineModel.dateFromString(maxDuration),
			min: new Date(1970, 0, 1),
		});
		setUpdateTimeline(false);
	}, []);

	const onSelect = (properties) => {
		setselectedItems(properties.items);
		properties.event.stopPropagation();
	};

	const openSelectedFilter = (e) => {
		setfilterName(e);
		props.setshowAddFilterDialog(true);
	};

	const runFunction = async (item, second) => {
		setPauseCanvas(true);
		if (second) {
			if (item.includes("Text")) {
				await deleteTextFilter(item, second);
				return;
			}
		} else if (item?.id.includes("Text")) {
			await deleteTextFilter(item);
			return;
		}

		await buttonDel(item.id);
	};

	const deleteTextFilter = async (item, second) => {
		setPauseCanvas(true);

		let t, t1;
		if (second) {
			t = item.split(":");
			t1 = item.split(":")[t.length - 1];
		} else {
			t = item.id.split(":");
			t1 = item.id.split(":")[t.length - 1];
		}
		const url = `${server.apiUrl}/project/${projectUrl}/dynamicText/textUpdate/${t1}`;

		try {
			const res = await axios.delete(url);

			// we will save the version of current xml
			let oldXml = [...XMLFiles];
			oldXml.push(res?.data?.projectXMLFILE);
			setXMLFiles(oldXml);

			const t2 = Object.values(allTextFilterRef.current)[t1];
			const secondClone = Object.values(allTextFilterRef.current).filter((el) => el !== t2);
			await props.setAllAssetFilter(secondClone);
			setAddTextClicked(false);
			toast.success(`${t("timeline.deletedSuccess")}`);
		} catch (error) {
			toast.error(`${t("error.serverError")}`);
		}
	};
	const addTextButtonHandler = () => {
		setPauseCanvas(true);
		props.setshowAddTextModal(true);
	};

	const addShapesButtonHandler = async () => {
		setPauseCanvas(true);
		setShowShapesModal(true);
	};

	const closeAddFilterDialog = () => {
		props.setshowAddFilterDialog(false);
	};

	const doubleClick = (properties) => {
		if (isMoving) return;
		// if not done by user return
		// if (!timeline.getEventProperties(event)?._handled?.doubleTap) return;
		setPauseCanvas(true);

		if (!properties.item) return;
		// videotrack1 is for images and 2 is for shapes
		if (
			properties.item &&
			!properties.item.includes("TextTrack") &&
			properties.item &&
			!properties.item.includes("videotrack1") &&
			!properties.item.includes("videotrack2")
		) {
			setShowAllFilterModal(true);
			return;
		} else if (properties.item.includes("videotrack1")) {
			setShowImageModal(true);
			return;
			/// forr image track wee will show imagee info
		} else if (properties.item.includes("videotrack2")) {
			setEditShapeModal(true);
			setShowShapesModal(true);
			return;
		} else {
			const itemSplit = properties.item.split(":");
			const itemSelectedIndex = itemSplit[1];
			setAddTextClicked(true);
			setTextModalInfo(allTextFilterRef.current[itemSelectedIndex]);
			setDeleteTextIndex(itemSelectedIndex);
			return;
		}
	};

	const buttonSplit = async () => {
		setPauseCanvas(true);

		try {
			if (selectedItems.length !== 1) {
				checkForSelectedItemToastError();
				return;
			}

			// now we need to check if these items have or links with any kind of transition -> if they have it we need to delete them
			if (selectedItems[0].includes("videotrack0") || selectedItems[0].includes("audiotrack")) {
				const item = getItemFromTrackIndex(selectedItems[0]);
				const splitTime = TimelineModel.dateToString(timelineRef.current.getCustomTime());
				if (splitTime === "00:00:00,000") {
					return toast.error(`${t("timeline.dragToDesiredLocation")}`, {
						position: "top-center",
						hideProgressBar: true,
						closeOnClick: true,
						autoClose: 2000,
					});
				}
				const splitItemTime = timeManager.subDuration(splitTime, item.start);
				if (splitTime <= item.start || splitTime >= item.end) return;

				const itemPath = selectedItems[0].split(":");
				const url = `${server.apiUrl}/project/${projectUrl}/item/split`;

				const data = {
					track: itemPath[0],
					item: Number(itemPath[1]),
					time: splitItemTime,
				};

				let videoTransParams = {};
				const urlTransition = `${server.apiUrl}/project/${projectUrl}/transitionVideo`;
				let responseTransitionDelete;

				if (data.track === "videotrack0") {
					let curItem = props.items.video[0].items[data.item];
					let prevItem = props.items.video[0].items[data.item - 1];
					if (curItem?.transitionFrom && curItem?.transitionTo) {
						// now we need to delete the current transition first
						videoTransParams = {
							item: data.item,
							itemA: data.item,
							itemB: data.item + 1,
							transition: "luma",
							transitionTo: curItem?.transitionTo,
							transitionFrom: curItem?.transitionFrom,

							track: data?.track,
						};

						try {
							responseTransitionDelete = await videoTransitionDelete(
								responseTransitionDelete,
								urlTransition,
								videoTransParams
							);
						} catch (error) {
							console.log(error);
						}
					} else if (
						prevItem &&
						prevItem?.transitionFrom &&
						prevItem?.transitionTo &&
						prevItem?.end === curItem?.start
					) {
						// if there is not any transition in current item but  in prev item we need to check and delete cause it has effect on this item as well
						videoTransParams.item = data.item - 1;
						videoTransParams.itemA = data.item - 1;
						videoTransParams.itemB = data.item;
						videoTransParams.transitionTo = prevItem?.transitionTo;
						videoTransParams.transitionFrom = prevItem?.transitionFrom;
						videoTransParams.track = data?.track;
						videoTransParams.transition = "luma";
						try {
							responseTransitionDelete = await videoTransitionDelete(
								responseTransitionDelete,
								urlTransition,
								videoTransParams
							);
						} catch (error) {
							console.log(error);
						}
					}
				}

				let responseitemDelete = await axios.put(url, data);
				// if there was no transition then we have to save XML file from the above api else use XML file from transtion delete API
				if (
					data.track !== "videotrack0" ||
					responseTransitionDelete?.data?.msg !== "Item Deleted"
				) {
					// this means no transition is present and we have to save XML info
					let oldXml = [...XMLFiles];
					oldXml.push(responseitemDelete?.data?.projectXMLFILE);
					setXMLFiles(oldXml);
				}

				if (responseitemDelete?.data.msg === "Item still contains transition") {
					// we have to send data to delete cause sometime item gets broken into 2 transtion
					// now there is one item les and we have to decrease theitem
					videoTransParams.item = videoTransParams.item - 1;
					videoTransParams.itemA = videoTransParams.item - 1;
					videoTransParams.itemB = videoTransParams.item;
					videoTransParams.track = data?.track;
					videoTransParams.transition = "luma";

					let rees1;
					await videoTransitionDelete(rees1, urlTransition, videoTransParams, true);

					await axios.put(url, data);
					forceUpdate();
					props.loadData(true);
				} else {
					forceUpdate();
					props.loadData(true);
				}
			} else {
				toast.error(`${t("timeline.onlyValidVideoAsset")}`);
			}
		} catch (error) {
			props.fetchError(error.message);
		}
	};

	const addEffectsHandler = () => {
		setPauseCanvas(true);

		console.log("selectedItems", selectedItems);

		if (selectedItems.length === 0) {
			checkForSelectedItemToastError();
			return;
		}

		setShowAllEffectsModal(true);
	};

	// brightness handle
	const brightnessHandler = () => {
		setPauseCanvas(true);

		if (selectedItems.length === 0) {
			checkForSelectedItemToastError();
			return;
		}

		if (selectedItems[0].includes("videotrack0")) {
			setfilterName("Brightness");
			props.setshowAddFilterDialog(true);
		} else {
			toast.error(`${t("timeline.onlyValidVideoAsset")}`);
		}
	};

	const backgroundColorHanndler = () => {
		setPauseCanvas(true);

		if (selectedItems.length === 0) {
			checkForSelectedItemToastError();
			return;
		}

		if (selectedItems[0].includes("videotrack0")) {
			setfilterName("Background Colour");
			props.setshowAddFilterDialog(true);
		} else {
			toast.error(`${t("timeline.onlyValidVideoAsset")}`);
		}
	};
	const fadeInBrightnessHandler = () => {
		setPauseCanvas(true);

		if (selectedItems.length === 0) {
			checkForSelectedItemToastError();
			return;
		}

		if (selectedItems[0].includes("videotrack0")) {
			setfilterName("Fade In Brightness");
			props.setshowAddFilterDialog(true);
		} else {
			toast.error(`${t("timeline.onlyValidVideoAsset")}`);
		}
	};
	const fadeOutBrightnessHandler = () => {
		setPauseCanvas(true);

		if (selectedItems.length === 0) {
			checkForSelectedItemToastError();
			return;
		}
		if (selectedItems[0].includes("videotrack0")) {
			setfilterName("Fade Out Brightness");
			props.setshowAddFilterDialog(true);
		} else {
			toast.error(`${t("timeline.onlyValidVideoAsset")}`);
		}
	};
	const IncreaseVolumeHandler = () => {
		setPauseCanvas(true);

		if (selectedItems.length === 0) {
			checkForSelectedItemToastError();
			return;
		}
		if (
			selectedItems[0].includes("videotrack0") ||
			selectedItems[0].includes("audiotrack0") ||
			selectedItems[0].includes("audiotrack1")
		) {
			setfilterName("Fade In Volume");
			props.setshowAddFilterDialog(true);
		} else {
			toast.error(`${t("timeline.onlyValidVideoAndAudioAsset")}`);
		}
	};

	const stopCanvasVideoPlaying = () => {
		setPauseCanvas(true);

		if (selectedItems.length === 0) {
			checkForSelectedItemToastError();
		}
	};

	const buttonDel = async (id) => {
		setPauseCanvas(true);

		let selectedItem;
		if (id) {
			selectedItem = id;
		} else {
			selectedItem = selectedItems[0];
		}

		try {
			if (!selectedItem) {
				checkForSelectedItemToastError();
				return;
			}

			setLoadingDelete(true);

			const itemPath = selectedItem.split(":");
			const url = `${server.apiUrl}/project/${projectUrl}/item`;
			const data = {
				track: itemPath[0],
				item: Number(itemPath[1]),
			};

			const track = TimelineModel.findTrack(props.items, itemPath[0]);

			try {
				// if it is an audiotrack we have to0 delete the transition as well. We have to deleetee the transition first before deleting the item, We need the start and end duration of the item. we have added transition only wheen audio file is added so we have to delete them whenit is deleted
				if (track.id === "audiotrack0" || track.id === "audiotrack1") {
					const urlTransition = `${server.apiUrl}/project/${projectUrl}/transition`;
					const dataForTransition = {
						startDuration: track.items[data.item].start,
						endDuration: track.items[data.item].end,
						audioTrackIndex: track.id === "audiotrack0" ? 0 : 1,
					};
					// send delete request
					const respo = await axios.delete(urlTransition, {
						data: dataForTransition,
					});

					// if it is audiotrack we have to save this XML
					// we will save the version of current xml
					let oldXml = [...XMLFiles];
					oldXml.push(respo?.data?.projectXMLFILE);
					setXMLFiles(oldXml);
				}
			} catch (error) {
				console.log(error);
				toast.error(`${t("timeline.unableToDeleteAudioFile")}`);
				return setLoadingDelete(false);
			}

			// In the delete api always wrap the data in curly bracket else the data will not be sent to
			let videoTransParams = {};
			const urlTransition = `${server.apiUrl}/project/${projectUrl}/transitionVideo`;
			let responseTransitionDelete;

			if (data.track === "videotrack0") {
				let curItem = props.items.video[0].items[data.item];
				let prevItem = props.items.video[0].items[data.item - 1];
				if (curItem?.transitionFrom && curItem?.transitionTo) {
					// now we need to delete the current transition first
					videoTransParams = {
						item: data.item,
						itemA: data.item,
						itemB: data.item + 1,
						transition: "luma",
						transitionTo: curItem?.transitionTo,
						transitionFrom: curItem?.transitionFrom,
						track: data?.track,
					};

					try {
						responseTransitionDelete = await videoTransitionDelete(
							responseTransitionDelete,
							urlTransition,
							videoTransParams
						);
					} catch (error) {
						console.log(error);
					}
				} else if (
					prevItem &&
					prevItem?.transitionFrom &&
					prevItem?.transitionTo &&
					prevItem?.end === curItem?.start
				) {
					// if there is not any transition in current item but  in prev item we need to check and delete cause it has effect on this item as well

					videoTransParams.item = data.item - 1;
					videoTransParams.itemA = data.item - 1;
					videoTransParams.itemB = data.item;
					videoTransParams.transitionTo = prevItem?.transitionTo;
					videoTransParams.transitionFrom = prevItem?.transitionFrom;
					videoTransParams.track = data?.track;
					videoTransParams.transition = "luma";

					try {
						responseTransitionDelete = await videoTransitionDelete(
							responseTransitionDelete,
							urlTransition,
							videoTransParams
						);
					} catch (error) {
						console.log(error);
					}
				}
			}

			const res = await axios.delete(url, { data });

			if (track.id !== "audiotrack0" || track.id !== "audiotrack1") {
				// if the track is not audio then we save this ->we also need to check for transtition now which exists for only video

				if (responseTransitionDelete?.data?.msg !== "Item Deleted") {
					// means there was no transition
					let oldXml = [...XMLFiles];
					oldXml.push(res?.data?.projectXMLFILE);
					setXMLFiles(oldXml);
				}
			}
			const runSuccess = () => {
				setselectedItems([]);
				setLoadingDelete(false);
				props.loadData(true);

				toast.info(`${t("shared.deleteSuccessfully")}`);
			};

			if (res?.data.msg === "Item still contains transition") {
				// we have to send data to delete cause sometime item gets broken into 2 transtion
				// now there is one item les and we have to decrease theitem
				videoTransParams.item = videoTransParams.item - 1;
				videoTransParams.itemA = videoTransParams.item - 1;
				videoTransParams.itemB = videoTransParams.item;
				videoTransParams.track = data?.track;
				videoTransParams.transition = "luma";
				let rees1;
				// here we  dont save our XML cause it will send same request again

				await videoTransitionDelete(rees1, urlTransition, videoTransParams, true);

				await axios.delete(url, { data });

				runSuccess();
			} else {
				runSuccess();
			}
		} catch (error) {
			console.log(error);

			setLoadingDelete(false);
			props.fetchError(error.message);
		}
	};

	const getItemFromTrackIndex = (trackIndex) => {
		if (trackIndex) {
			const itemPath = trackIndex.split(":");
			const trackItems = TimelineModel.findTrack(props.items, itemPath[0]).items;
			return TimelineModel.findItem(trackItems, Number(itemPath[1]));
		} else return;
	};

	const onTimeChanged = (event) => {
		let seekedtime = event.time.toISOString() + "#" + Math.random();
		props.setTimelineTimeToCanvas2(seekedtime);
		forceUpdate();
		setPauseCanvas(true);
	};

	const videoTransitionDelete = async (res, url, dataParams, dontSaveXML) => {
		let data = {};
		data.item = dataParams.item;
		data.itemA = dataParams.item;
		data.itemB = dataParams.item + 1;
		data.transition = "luma";
		data.duration = timeManager.subDuration(dataParams.transitionTo, dataParams.transitionFrom);
		data.track = dataParams.track;

		res = await axios.delete(url, { data });
		if (!dontSaveXML) {
			// here we have to save the XML
			let oldXml = [...XMLFiles];
			oldXml.push(res?.data?.projectXMLFILE);
			setXMLFiles(oldXml);
		}
		return res;
	};
	const onMoving = (item, callback) => {
		const minDate = new Date(1970, 0, 1);
		if (item.start.getTime() < minDate.getTime()) {
			// If the item's start time is before the minimum date,
			// set its start time to the minimum date.
			const diff = item.end.getTime() - item.start.getTime();
			item.start = minDate;
			item.end = new Date(minDate.getTime() + diff);
		}
		isMoving = true;
		const i = item.id.split(":")[0];
		// we allow only audio to move from one group to other
		if (!i.includes("audiotrack")) {
			item.group = item.previousGroup; // set group to previous group
			timelineRef.current.itemsData.update(item);
		}

		// if there is no item at the new position, allow move
		callback(itemMove(item));
		setTimeout(() => {
			isMoving = false;
		}, 100);
	};

	const moveTextTrack = async (item) => {
		if (!item) return;

		let index = item.id.split(":")[1];

		const url = `${server.apiUrl}/project/${projectUrl}/dynamicText/textUpdate/${index}`;
		let newFilter = {
			filter: "dynamictext",
			params: {
				in: TimelineModel.dateToString(item.start),
				out: TimelineModel.dateToString(item.end),
				fgcolour: Object.values(item.el).find((el1) => el1.name === "fgcolour")?.value,
				bgcolour: Object.values(item.el).find((el1) => el1.name === "bgcolour")?.value,
				argument: Object.values(item.el).find((el1) => el1.name === "argument")?.value,
				size: Object.values(item.el).find((el1) => el1.name === "size")?.value,

				style: Object.values(item.el).find((el1) => el1.name === "style")?.value,
				weight: Object.values(item.el).find((el1) => el1.name === "weight")?.value,
				geometry: Object.values(item.el).find((el1) => el1.name === "geometry")?.value,
				family: Object.values(item.el).find((el1) => el1.name === "family")?.value,
			},
			textStatus: true,
		};
		const res1 = await axios.post(url, newFilter);
		let oldXml = [...XMLFiles];
		oldXml.push(res1?.data?.projectXMLFILE);
		setXMLFiles(oldXml);

		props.loadData(true);

		return;
	};

	const onMoveTransitionDelete = async (
		data,
		videoTransParams,
		responseTransitionDelete,
		urlTransition
	) => {
		if (data.track === "videotrack0") {
			let curItem, prevItem1;

			curItem = currentTimelineItems?.current.video[0].items[data.item];
			prevItem1 = currentTimelineItems?.current.video[0].items[data.item - 1];

			if (curItem?.transitionFrom && curItem?.transitionTo) {
				// now we need to delete the current transition first

				videoTransParams = {
					item: data.item,
					itemA: data.item,
					itemB: data.item + 1,
					transition: "luma",
					transitionTo: curItem?.transitionTo,
					transitionFrom: curItem?.transitionFrom,

					track: data?.track,
				};

				try {
					responseTransitionDelete = await videoTransitionDelete(
						responseTransitionDelete,
						urlTransition,
						videoTransParams
					);
					return [responseTransitionDelete, videoTransParams];
				} catch (error) {
					console.log(error);
				}
				// return;
			} else if (
				prevItem1 &&
				prevItem1?.transitionFrom &&
				prevItem1?.transitionTo &&
				prevItem1?.end === curItem?.start
			) {
				// if there is not any transition in current item but  in prev item we need to check and delete cause it has effect on this item as well
				videoTransParams.item = data.item - 1;
				videoTransParams.itemA = data.item - 1;
				videoTransParams.itemB = data.item;
				videoTransParams.transitionTo = prevItem1?.transitionTo;
				videoTransParams.transitionFrom = prevItem1?.transitionFrom;
				videoTransParams.track = data?.track;
				videoTransParams.transition = "luma";

				try {
					responseTransitionDelete = await videoTransitionDelete(
						responseTransitionDelete,
						urlTransition,
						videoTransParams
					);
					return [responseTransitionDelete, videoTransParams];
				} catch (error) {
					console.log(error);
				}
			} else {
				return [null, null];
			}
		}
	};
	const onMove = async (item, callback) => {
		const minDate = new Date(1970, 0, 1);
		if (item.start.getTime() < minDate.getTime()) {
			// If the item's start time is before the minimum date,
			// set its start time to the minimum date.
			const diff = item.end.getTime() - item.start.getTime();
			item.start = minDate;
			item.end = new Date(minDate.getTime() + diff);
		}
		let prevItemArray, prevItem;
		const i = item.id.split(":")[0];

		if (i.includes("audiotrack")) {
			prevItemArray = currentTimelineItems?.current.audio.find((el) => el.id === i);
			prevItem = prevItemArray.items[item.id.split(":")[1]];
		}

		setPauseCanvas(true);

		if (i.includes("TextTrack")) {
			// text track ->only duration change

			return moveTextTrack(item);
		}

		/*  if (i.includes("videotrack1") || i.includes("videotrack2")) {
            item = itemMove(item);
        } */

		try {
			item.className = "video";
			const itemPath = item.id.split(":");

			const currentItem = timelineRef.current.itemsData.get().find((el) => el.id === item.id);

			let itemsBefore = timelineRef.current.itemsData
				.get()
				.filter((el) => el.group === item.group)
				.filter((el2) => el2.group.includes("blank"))
				.filter((el1) => el1.start < new Date(JSON.parse(JSON.stringify(item.start.getTime()))));

			if (itemsBefore.length > 0 || currentItem.start === item.start) {
				// unselecting item
				timelineRef.current.setSelection([]);
				setselectedItems([]);
				return callback(null);
			}

			// end of not allowing item to move

			// videotrack 1 and 2 means image/shape and this includes stretching/reduce of length
			// here we need to check if user is just trying to stretch item instead of moving it

			// finding videeotrack1 or 2

			let timelineItems = currentTimelineItems?.current;

			if (item.start.getFullYear() < 1970 || item === null) {
				timelineRef.current.setSelection([]);
				setselectedItems([]);
				return callback(null);
			}
			let videoTransParams = {};

			const urlTransition = `${server.apiUrl}/project/${projectUrl}/transitionVideo`;
			const data = {
				track: itemPath[0],
				trackTarget: item.group,
				item: Number(itemPath[1]),
				time: TimelineModel.dateToString(item.start),
				endTime: TimelineModel.dateToString(item.end),
				prevItemStart: prevItem?.start,
				prevItemEnd: prevItem?.end,
			};

			let originalTrack;

			if (i.includes("audio")) {
				originalTrack =
					timelineItems?.audio[i.includes("audiotrack0") ? 0 : 1].items[Number(itemPath[1])];
			} else {
				// includes videotrack(video, image and shape)
				originalTrack =
					timelineItems?.video[i.includes("videotrack0") ? 0 : i.includes("videotrack1") ? 1 : 2]
						.items[Number(itemPath[1])];
			}

			// total time of current item

			let originalTrackTotalTime = timeManager.subDuration(originalTrack.end, originalTrack.start);

			// current item start
			let changedTrackStartTime = TimelineModel.dateToString(item.start);
			/* current item end */
			let changedTrackEndTime = TimelineModel.dateToString(item.end);
			// if they are not equal it means they are trying to stretch or reduce
			// TODO: mohit
			// their differnce should be greater than 0.5 secodn
			if (
				timeManager.subDuration(changedTrackEndTime, changedTrackStartTime) !==
					originalTrackTotalTime &&
				changedTrackEndTime > changedTrackStartTime &&
				Math.abs(
					splitTime(
						timeManager.subDuration(
							timeManager.subDuration(changedTrackEndTime, changedTrackStartTime),
							originalTrackTotalTime
						)
					)
				) > 0.5
			) {
				// check first if item Start time is less than timelinestarttime ->it means we also have to add blank before the actual Item
				let increaseBlankDuration;
				let decreaseBlankDuration;
				let decreaseLeft, increaseLeft;
				let decreaseRight, increaseRight;
				// originalTime= 4-10 examplee

				if (changedTrackStartTime > originalTrack.start) {
					// not applicable for videeo elements wee dont allow to change from left drag
					// example ->6-10

					// if (i.includes("videotrack0")) {
					//     timelineRef.current.setSelection([]);
					//     setselectedItems([]);
					//     return callback(null);
					// }
					// it means the are trying to decrese the size from left side and we have to insert blank so add blank
					increaseBlankDuration = timeManager.subDuration(
						changedTrackStartTime,
						originalTrack.start
					);
					decreaseLeft = increaseBlankDuration;
				} else if (changedTrackStartTime < originalTrack.start) {
					// example ->2-10

					// if (i.includes("videotrack0")) {
					//     timelineRef.current.setSelection([]);
					//     setselectedItems([]);
					//     return callback(null);
					// }
					// it means we need to decrease blankDuration
					// not applicable for videeo elements

					decreaseBlankDuration = timeManager.subDuration(
						originalTrack.start,
						changedTrackStartTime
					);
					increaseLeft = decreaseBlankDuration;
				} else if (changedTrackEndTime < originalTrack.end) {
					// example ->4-8

					// it means they are incresing duration
					increaseBlankDuration = timeManager.subDuration(originalTrack.end, changedTrackEndTime);
					decreaseRight = increaseBlankDuration;
				} else if (changedTrackEndTime > originalTrack.end) {
					// it means they are decressing duration
					// example ->4-12
					decreaseBlankDuration = timeManager.subDuration(changedTrackEndTime, originalTrack.end);
					increaseRight = decreaseBlankDuration;
				}

				if (i.includes("videotrack1") || i.includes("videotrack2")) {
					let url = `${server.apiUrl}/project/${projectUrl}/editimage`;
					let data1 = {
						duration: timeManager.subDuration(changedTrackEndTime, changedTrackStartTime),
						item: Number(itemPath[1]),
						timelineStretch: true,
						editingShape: i.includes("videotrack2") ? true : false,
						timelineItemStart: changedTrackStartTime,
						timelineItemEnd: changedTrackEndTime,
						increaseBlankDuration,
						decreaseBlankDuration,
						increaseRight,
						increaseLeft,
						decreaseLeft,
						decreaseRight,
					};

					await axios.patch(url, data1);

					props.loadData(true);
					return;
				} else if (!i.includes("audiotrack")) {
					// transition delete if exists
					let videoTranRes = await onMoveTransitionDelete(
						data,
						videoTransParams,
						responseTransitionDelete,
						urlTransition
					);

					videoTransParams = videoTranRes[1];
					responseTransitionDelete = videoTranRes[0];
					// we check the time for audio and video and ssee if they are increasing/decreasing
					let url = `${server.apiUrl}/project/${projectUrl}/editVideo`;
					let data2 = {
						duration: timeManager.subDuration(changedTrackEndTime, changedTrackStartTime),
						item: Number(itemPath[1]),

						timelineItemStart: changedTrackStartTime,
						timelineItemEnd: changedTrackEndTime,
						increaseBlankDuration,
						decreaseBlankDuration,
						increaseRight,
						increaseLeft,
						decreaseLeft,
						decreaseRight,
					};

					let resItemMove = await axios.patch(url, data2);
					if (responseTransitionDelete?.data?.msg !== "Item Deleted") {
						// this means no transition is present and we have to save XML info
						let oldXml = [...XMLFiles];
						oldXml.push(resItemMove?.data?.projectXMLFILE);
						setXMLFiles(oldXml);
					}
					if (resItemMove?.data?.msg === "Item still contains transition") {
						// now we need to delete the current transition first
						videoTransParams.item = videoTransParams.item - 1;
						videoTransParams.itemA = videoTransParams.item - 1;
						videoTransParams.itemB = videoTransParams.item;
						videoTransParams.track = data?.track;
						videoTransParams.transition = "luma";
						let rees1;
						// here we  dont save our XML cause it will send same request again
						await videoTransitionDelete(rees1, urlTransition, videoTransParams, true);
						resItemMove = await axios.patch(url, data2);
					}
					props.loadData(true);
					return;
				}
			}

			// end of item stretch or reduce
			const url = `${server.apiUrl}/project/${projectUrl}/item/move`;
			// here we need to do same thing if element has transition delete it
			let responseTransitionDelete;
			if (data.track === "videotrack0") {
				let videoTranRes = await onMoveTransitionDelete(
					data,
					videoTransParams,
					responseTransitionDelete,
					urlTransition
				);
				videoTransParams = videoTranRes[1];
				responseTransitionDelete = videoTranRes[0];
			}

			let res3 = await axios.put(url, data);
			if (responseTransitionDelete?.data?.msg !== "Item Deleted") {
				// this means no transition is present and we have to save XML info
				let oldXml = [...XMLFiles];
				oldXml.push(res3?.data?.projectXMLFILE);
				setXMLFiles(oldXml);
			}
			if (res3?.data?.msg === "Item still contains transition") {
				// now we need to delete the current transition first
				videoTransParams.item = videoTransParams.item - 1;
				videoTransParams.itemA = videoTransParams.item - 1;
				videoTransParams.itemB = videoTransParams.item;
				videoTransParams.track = data?.track;
				videoTransParams.transition = "luma";
				let rees1;
				// here we  dont save our XML cause it will send same request again
				await videoTransitionDelete(rees1, urlTransition, videoTransParams, true);
				res3 = await axios.put(url, data);
			}
			setLoading(true);
			setLoading(false);
			props.loadData(true);
		} catch (error) {
			props.fetchError(error.message);
			console.log(error);
		}
	};

	const itemMove = (item) => {
		if (item.id.includes("TextTrack")) {
			if (item.start.getFullYear() < 1970) return null;
			return item;
		}
		setPauseCanvas(true);
		// Deny move before zero time
		if (item.start.getFullYear() < 1970) return null;
		const itemPath = item.id.split(":");
		item.className = item.className.includes("video") ? "video" : "audio";
		const itemIndex = itemPath[0] === item.group ? Number(itemPath[1]) : null;
		const start = TimelineModel.dateToString(item.start);
		const end = TimelineModel.dateToString(item.end);
		const track = TimelineModel.findTrack(currentTimelineItems?.current, item.group);
		const collision = TimelineModel.getItemInRange(track, itemIndex, start, end);

		if (collision.length === 0) {
			// Free
			return item;
		} else if (collision.length > 1) {
			// Not enough space
			return null;
		} else {
			// Space maybe available before/after item
			let itemStart = "";
			let itemEnd = "";
			const duration = timeManager.subDuration(end, start);
			if (
				timeManager.middleOfDuration(start, end) <
				timeManager.middleOfDuration(collision[0].start, collision[0].end)
			) {
				// Put before
				item.className = item.className.includes("video")
					? "video stick-right"
					: "audio stick-right";
				itemEnd = collision[0].start;
				item.end = TimelineModel.dateFromString(itemEnd);

				itemStart = timeManager.subDuration(collision[0].start, duration);
				item.start = TimelineModel.dateFromString(itemStart);

				if (item.start === null) {
					return null; // Not enough space at begining of timeline
				}
			} else {
				// Put after
				// alert(1);
				item.className = item.className.includes("video") ? "video stick-left" : "audio stick-left";
				itemStart = collision[0].end;
				item.start = TimelineModel.dateFromString(collision[0].end);
				itemEnd = timeManager.addDuration(collision[0].end, duration);
				item.end = TimelineModel.dateFromString(itemEnd);
			}
			// Check if there is enough space
			const track = TimelineModel.findTrack(currentTimelineItems?.current, item.group);

			if (TimelineModel.getItemInRange(track, itemIndex, itemStart, itemEnd).length === 0) {
				return item;
			}

			return null;
		}
	};
	const insertShapeHandler = async (el, color, duration, item, editMode) => {
		// imageHeight: 183;
		// imageWidth: 251;
		// imageX: 321;
		// imageY: 116;

		/* 
            filter.1 ->imageX
            filter.2 ->imageY
            filter.3->imageWIdth
            filter.4 ->imageHeight
        */

		// we have to adjust x, y image and height for mlt

		let X = (el.imageX + el.imageWidth / 2) / 640;
		let Y = (el.imageY + el.imageHeight / 2) / 360;
		let imageWid = el.imageWidth / 2 / 640;
		let imageHei = el.imageHeight / 2 / 360;

		try {
			let url;
			if (!editMode) {
				url = `${server.apiUrl}/project/${projectUrl}/addshapes`;
			} else {
				url = `${server.apiUrl}/project/${projectUrl}/editimage`;
			}

			let newFilter = {
				filter: "mask_start",
				params: {
					"filter.9": 0,
					"filter.0": 0,
					"filter.1": X,
					"filter.2": Y,
					"filter.3": imageWid,
					"filter.4": imageHei,
					"filter.5": 0.5,
					"filter.6": 0,
					"filter.imageX": el.imageX,
					"filter.imageY": el.imageY,
					"filter.imageWidth": el.imageWidth,
					"filter.imageHeight": el.imageHeight,
					"filter.color": color,
				},
				color,
				duration,
				// since this will be the last item to be added there
				item: props.items.video[2].items.length,
			};
			let data = {};
			if (editMode) {
				data = {
					newFilter,
					editingShape: true,
					duration,
					item,
				};
			}

			let res;
			if (!editMode) {
				// we are adding shapes
				res = await axios.post(url, newFilter);
			} else {
				// we are editing shapes
				res = await axios.patch(url, data);
			}

			let oldXml = [...XMLFiles];
			oldXml.push(res?.data?.projectXMLFILE);
			setXMLFiles(oldXml);

			let shapesID = res?.data.id;
			setShowShapesModal(false);

			props.addShapesResource(shapesID, duration, "videotrack2", newFilter, editMode);
		} catch (error) {
			console.log(error);
		}
	};

	const imageInputHandler = (e) => {
		if (e._d) {
			const d = new Date(e._d.getTime());
			const start = date.format(d, "HH:mm:ss,SSS");
			setShapeDuration(start);
			setImageDuration(start);
		}
	};

	const insertImageHandler = async (imageCorrdinates, adminItems) => {
		const itemSplit = selectedItems[0].split(":");
		const itemSelectedIndex = itemSplit[itemSplit.length - 1];

		let X = manageImageXPosition(imageCorrdinates.imageX);
		let Y = manageImageYPosition(imageCorrdinates.imageY);
		let imageWid = manageImageXPosition(imageCorrdinates.imageWidth);
		let imageHei = manageImageYPosition(imageCorrdinates.imageHeight);
		let rect = `${X} ${Y} ${imageWid} ${imageHei} 1`;
		let rect1 = `${imageCorrdinates.imageX} ${imageCorrdinates.imageY} ${imageCorrdinates.imageWidth} ${imageCorrdinates.imageHeight}`;

		const url = `${server.apiUrl}/project/${projectUrl}/editimage`;
		let duration = imageDuration;
		const data = {
			item: itemSelectedIndex,
			duration,
			editingShape: false,
			rect,
			rect1,
		};
		const res = await axios.patch(url, data);
		if (res.data.status !== "success") {
			toast.error(`${t("error.serverError")}`);
		} else {
			let oldXml = [...XMLFiles];
			oldXml.push(res?.data?.projectXMLFILE);
			setXMLFiles(oldXml);

			setShowImageModal(false);
			setImageDuration("");

			await props.loadData(true);
			toast.success(`${t("shared.durationChangedSuccess")}`);
		}
	};

	const imageHanlderCancel = () => {
		setShowImageModal(!showImageModal);
	};

	const closeShapeModal = () => {
		setImageDuration("");
		setShapeDuration("");
		setEditShapeModal(false);

		setShowShapesModal(false);
	};

	const transitionButtonHandler = () => {
		stopCanvasVideoPlaying();

		const itemPath = selectedItems[0]?.split(":");
		let itemNum = Number(itemPath[1]) + 1;
		const item = getItemFromTrackIndex(selectedItems[0]);
		// if selected item is last item then we cannot apply transtion
		// we have to check if there is a blank video after current item ->cause mlt does not add any
		if (props.items.video[0]?.items[itemNum]) {
			// now we have to check if the nexxt element is blank
			const nextItemStart = props.items.video[0]?.items[itemNum].start;
			if (nextItemStart !== item.end) {
				return toast.error(`Transition cannot be applied to video followed by blank.`, {
					position: "top-center",
					hideProgressBar: true,
					closeOnClick: true,
					autoClose: 4000,
				});
			}
		}

		if (itemNum === props.items.video[0]?.items.length) {
			return toast.error(`Transition cannot be applied to last video`, {
				position: "top-center",
				hideProgressBar: true,
				closeOnClick: true,
				autoClose: 4000,
			});
		}

		if (selectedItems.length > 0) {
			setShowTransitionModal(true);
		}
	};

	const addTransitionHandler = async (
		transitionItem,
		transitionDuration,
		editingItemLumaFilter
	) => {
		const url = `${server.apiUrl}/project/${projectUrl}/transitionVideo`;
		let transDurValu = {
			"00:00:01,000": 1,
			"00:00:02,000": 2,
			"00:00:03,000": 3,
			"00:00:04,000": 4,
		};
		let dur = Object.keys(transDurValu).find((el) => transDurValu[el] === transitionDuration * 1);
		const itemPath = selectedItems[0].split(":");

		const data = {
			track: itemPath[0],
			itemA: Number(itemPath[1]),
			itemB: Number(itemPath[1]) + 1,
			transition: transitionItem?.transition,
			duration: dur,
			resource: transitionItem?.resource,
			invert: transitionItem?.invert,
			editingItemLumaFilter: editingItemLumaFilter,
		};
		try {
			const res = await axios.post(url, data);

			console.log("res", res);

			if (res?.data?.code === 1) {
				return toast.error(
					"Transition cannot be applied to item containing FadeIn or FadeOut filter"
				);
			}

			let oldXml = [...XMLFiles];
			oldXml.push(res?.data?.projectXMLFILE);
			setXMLFiles(oldXml);

			let curItem = getItemFromTrackIndex(selectedItems[0]);

			let transitionParams = {
				params: {
					a_track: data.itemA,
					b_track: data.itemB,
					in: timeManager.subDuration(curItem.out, data?.duration),
					invert: data.invert,
					out: curItem?.out,
					resource: data.resource,
				},
				service: "luma",
				transitionFrom: timeManager.subDuration(curItem.out, data?.duration),
				transitionTo: curItem?.out,
				track: data?.track,
				item: data?.itemA,
				editingItemLumaFilter: editingItemLumaFilter,
			};
			props.addTransition(transitionParams, editingItemLumaFilter && editingItemLumaFilter);
		} catch (error) {
			console.log(error);
		}
	};

	const deleteTransitionHandler = async (
		transitionItem,
		transitionDuration,
		editingItemLumaFilter
	) => {
		const url = `${server.apiUrl}/project/${projectUrl}/transitionVideo`;
		let transDurValu = {
			"00:00:01,000": 1,
			"00:00:02,000": 2,
			"00:00:03,000": 3,
			"00:00:04,000": 4,
		};

		let dur = Object.keys(transDurValu).find((el) => transDurValu[el] === transitionDuration * 1);
		const itemPath = selectedItems[0].split(":");

		const data = {
			track: itemPath[0],
			// WE ARE PASSING ITEM JUST FOR DELETE OPERATION CHECK
			item: Number(itemPath[1]),
			itemA: Number(itemPath[1]),
			itemB: Number(itemPath[1]) + 1,
			transition: transitionItem?.transition,
			duration: dur,
			resource: transitionItem?.resource,
			invert: transitionItem?.invert,
			editingItemLumaFilter: editingItemLumaFilter,
		};
		try {
			const res = await axios.delete(url, { data });

			let oldXml = [...XMLFiles];
			oldXml.push(res?.data?.projectXMLFILE);
			setXMLFiles(oldXml);

			let transitionParams = {
				track: data?.track,
				item: data?.itemA,
			};
			props.deleteTransition(transitionParams);
		} catch (error) {
			console.log(error);
		}
	};

	const moveTimelineItem = (percentage) => {
		let range = timelineRef.current.getWindow();
		let interval = range.end - range.start;
		timelineRef.current.setWindow({
			start: range.start.valueOf() - interval * percentage,
			end: range.end.valueOf() - interval * percentage,
		});
	};

	const deleteConfirmModalHandler = () => {
		let item = {};
		item.id = selectedItems[0];
		runFunction(item);
		setShowConirmDeleteModal(false);
	};

	const setAudioMarkerPosition = () => {
		const time = props.timelineMarkerTime;
		const sortedAudioItems = audioItems.sort((a, b) => a.start - b.start);

		for (const audioItem of sortedAudioItems) {
			const containerId = getPlayerContainerId(audioItem);
			if (wfPlayers[containerId]) {
				if (audioItem.end <= time) {
					const diff =
						(audioItem.end - audioItem.start) / 1000 -
						(audioItem.end - audioItem.start) / (1000 * 60);
					wfPlayers[containerId].seek(diff);
				} else if (audioItem.start > time) {
					wfPlayers[containerId].seek(0);
				} else {
					const diff = (time - audioItem.start) / 1000;
					if (Math.abs(wfPlayers[containerId].currentTime - diff) > 1)
						wfPlayers[containerId].seek(diff);
				}
			}
		}
	};

	useEffect(() => {
		if (props.timelineMarkerTime) {
			setAudioMarkerPosition();
		}
	}, [props.timelineMarkerTime]);

	useEffect(() => {
		if (isAudioGraphEnabled) {
			for (const playerId in wfPlayers) {
				if (wfPlayers[playerId]) {
					wfPlayers[playerId].destroy();
				}
			}
			wfPlayers = {};
			for (const audioItem of audioItems) {
				const playerId = getPlayerContainerId(audioItem);

				let wf = wfPlayers[playerId];
				if (!wf) {
					setTimeout(() => {
						const root = document.getElementById(getPlayerContainerId(audioItem));

						let container = document.getElementById(`container-${getPlayerContainerId(audioItem)}`);
						if (!container) {
							container = document.createElement("div");
							container.id = `container-${getPlayerContainerId(audioItem)}`;
							if (audioItem.id.includes("audiotrack")) {
								container.style = "position: absolute; top: 0; right: 0px; left: 0; bottom: 0";
							} else {
								container.style =
									"position: absolute; right: 0px; left: 0; bottom: 0; height: 31px;";
							}

							root.parentNode.parentNode.appendChild(container);
							if (audioItem.id.includes("audiotrack")) {
								const divDel = document.createElement("div");
								divDel.classList.add("vis-delete");
								divDel.addEventListener("click", () => {
									showConfirmModalHandler(audioItem);
								});
								root.parentNode.parentNode.appendChild(divDel);
							}
						}
						// Calculate the difference in milliseconds
						const durationStr = timeManager.subDuration(audioItem.item.end, audioItem.item.start);
						let inittime = new Date(1970, 0, 1);
						const duration = (TimelineModel.dateFromString(durationStr) - inittime) / 1000;
						wf = wfPlayers[playerId];
						if (wf) return;

						let progressColor = "#ECEFF1",
							waveColor = "#7086A373";
						if (audioItem.id.includes("audiotrack")) {
							progressColor = "#D7F6FF";

							waveColor = "#80B4C773";
						} else {
							progressColor = "#ECEFF1";
							waveColor = "#7086A373";
						}

						wf = new WFPlayer({
							container,
							cursor: false,
							ruler: false,
							progress: false,
							padding: 0,
							// progressColor: progressColor,
							duration: duration,
							grid: false,
							waveColor,
						});

						if (audioItem.id.includes("audiotrack")) {
							wf.load(audioItem.url);
						} else {
							wf.load(`/project/WORKER/${projectUrl}/${audioItem.item.resource}.mp3`);
						}
						wfPlayers[playerId] = wf;
					}, 650);
				} else {
					wf.load(audioItem.url);
				}
			}
		}
	}, [audioItems, isAudioGraphEnabled]);

	return (
		<>
			{showConirmDeleteModal && (
				<DeleteModal
					showOnlyTextMessage={true}
					confirmIcon={mdiTrashCan}
					confirmHandler={deleteConfirmModalHandler}
					cancelHandler={() => setShowConirmDeleteModal(false)}
					inputLabel={"Are You sure you want to delete it ?"}
					confirmButtonText={t("partialScreenCapture.confirm")}
				/>
			)}
			{showTransitionModal && (
				<TransitionModal
					setShowTransitionModal={setShowTransitionModal}
					addTransitionHandler={addTransitionHandler}
					deleteTransitionHandler={deleteTransitionHandler}
					getItemFromTrackIndex={getItemFromTrackIndex}
					selectedItems={selectedItems}
					getItem={getItemFromTrackIndex}
				/>
			)}

			{showShapesModal && (
				<ShapesModal
					setShowImageModal={setShowImageModal}
					showShapesModal={showShapesModal}
					items={props.items}
					timeline={props.items}
					insertShapeHandler={insertShapeHandler}
					shapeHanlderCancel={closeShapeModal}
					shapeDuration={shapeDuration}
					setShapeDuration={setShapeDuration}
					project={projectUrl}
					imageInputHandler={imageInputHandler}
					resources={props.resources}
					adminItems={props.adminItems}
					editShapeModal={editShapeModal}
					selectedItems={selectedItems}
					imageDuration={imageDuration}
					setImageDuration={setImageDuration}
					timelineRef={timelineRef?.current}
					time={time}
					setGlobalBrandColor={setGlobalBrandColor}
					globalBrandColor={globalBrandColor}
				/>
			)}
			<section className="timelineSection">
				{addTextClicked && (
					<TextModal
						addTextClicked={addTextClicked}
						setAddTextClicked={setAddTextClicked}
						setTextModalInfo={setTextModalInfo}
						textModalInfo={textModalInfo}
						onClose={closeAddFilterDialog}
						onAdd={(filter, secondParameter) => props.onAddFilter(filter, secondParameter)}
						timelineTime={props.duration}
						setshowAddTextModal={props.setshowAddTextModal}
						project={projectUrl}
						deleteTextIndex={deleteTextIndex}
						setDeleteTextIndex={setDeleteTextIndex}
						allAssetFilter={props.allAssetFilter}
						setAllAssetFilter={props.setAllAssetFilter}
						selectedItems={selectedItems}
						runFunction={runFunction}
						setGlobalBrandColor={setGlobalBrandColor}
						globalBrandColor={globalBrandColor}
						globalFonts={props.globalFonts}
						setGlobalFonts={props.setGlobalFonts}
						timelineRef={timelineRef?.current}
					/>
				)}

				{showImageModal && (
					<ImageModal
						selectedItems={selectedItems}
						imageDuration={imageDuration}
						setImageDuration={setImageDuration}
						editImage={true}
						timeline={props.items}
						items={props.items}
						resources={props.resources}
						adminresources={props.adminItems}
						showImageModal={showImageModal}
						imageInputHandler={imageInputHandler}
						insertImageHandler={insertImageHandler}
						imageHanlderCancel={imageHanlderCancel}
						project={projectUrl}
						timelineRef={timelineRef?.current}
						time={time}
					/>
				)}

				{showAllFilterModal && (
					<ShowAllFilterModal
						item={selectedItems[0]}
						resources={props.resources.resources}
						getItem={getItemFromTrackIndex}
						project={projectUrl}
						onClose={closeAddFilterDialog}
						onAdd={(filter, secondParameter) => props.onAddFilter(filter, secondParameter)}
						onDel={(filter) => props.onDelFilter(filter)}
						fetchError={props.fetchError}
						gradientColor={props.gradientColor}
						setgradientColor={props.setgradientColor}
						hexColor={props.hexColor}
						sethexColor={props.sethexColor}
						filterName={filterName}
						openAddFilterDialogEdit={openAddFilterDialogEdit}
						setOpenAddFilterDialogEdit={setOpenAddFilterDialogEdit}
						setshowAddFilterDialog={props.setshowAddFilterDialog}
						setfilterName={setfilterName}
						showAllFilterModal={showAllFilterModal}
						setShowAllFilterModal={setShowAllFilterModal}
						loading={loading}
						setLoading={setLoading}
						showAddTextModal={props.showAddTextModal}
						setshowAddTextModal={props.setshowAddTextModal}
					/>
				)}

				{props.showAddTextModal && (
					<AddText
						onClose={closeAddFilterDialog}
						onAdd={(filter, secondParameter) => props.onAddFilter(filter, secondParameter)}
						timelineTime={props.duration}
						setshowAddTextModal={props.setshowAddTextModal}
						setGlobalBrandColor={setGlobalBrandColor}
						globalBrandColor={globalBrandColor}
						globalFonts={props.globalFonts}
						setGlobalFonts={props.setGlobalFonts}
						timelineRef={timelineRef?.current}
					/>
				)}

				{showAllEffectsModal && (
					<EffectsModal
						fadeInBrightnessHandler={fadeInBrightnessHandler}
						fadeOutBrightnessHandler={fadeOutBrightnessHandler}
						brightnessHandler={brightnessHandler}
						backgroundColorHanndler={backgroundColorHanndler}
						setShowAllEffectsModal={setShowAllEffectsModal}
						item={selectedItems[0]}
						getItem={getItemFromTrackIndex}
					/>
				)}

				<div className="timelineRow">
					<div className="timelineButton">
						<button onClick={addTextButtonHandler}>
							<Icon
								path={mdiText}
								title="User Profile"
								size={1}
								color="white"
								style={{ marginRight: "5px" }}
							/>
							{t("addText.addText")}
						</button>
						<button onClick={addShapesButtonHandler}>
							<Icon
								path={mdiShape}
								title="User Profile"
								size={1}
								color="white"
								style={{ marginRight: "5px" }}
							/>
							{t("timeline.addShapes")}
						</button>
						<button onClick={buttonSplit} disabled={props.timelineItemLength <= 0}>
							<Icon
								path={mdiScissorsCutting}
								title="User Profile"
								size={1}
								horizontal
								vertical
								rotate={90}
								color="white"
								style={{ marginRight: "5px" }}
							/>
							{t("timeline.cut")}
						</button>

						<button onClick={() => addEffectsHandler()}>
							{" "}
							<FontAwesomeIcon
								color="white"
								style={{ marginRight: "5px", width: "24px", height: "24px" }}
								icon="sun"
							/>
							Add Effects
						</button>

						<button onClick={IncreaseVolumeHandler}>
							<FontAwesomeIcon
								color="white"
								style={{ marginRight: "5px", width: "24px", height: "24px" }}
								icon="volume-up"
							/>
							{t("timeline.changeVolume")}
						</button>
						<button onClick={transitionButtonHandler}>
							<FontAwesomeIcon
								icon="object-ungroup"
								title="User Profile"
								color="white"
								style={{ marginRight: "5px", width: "24px", height: "24px" }}
							/>
							Transitions
						</button>
					</div>

					<div
						style={{
							marginRight: "50px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{timelineButtons.map((el) => (
							<button
								className="timelineCustomButton"
								key={el.id.toString()}
								onClick={el.onClick}
								disabled={el?.disabled}
								style={{ background: el?.disabled && "#555" }}
							>
								{el.icon}
							</button>
						))}
					</div>
				</div>
			</section>

			<footer>
				{mainCompLoading || loadingDelete || !currentTimelineItems.current ? (
					<div
						style={{
							display: "flex",
							justifyContent: "space-around",
							alignItems: "center",
							flexDirection: "column",
							margin: "30px auto",
							opacity: "0.5",
							pointerEvents: "none",
							zIndex: "100",
						}}
						id="timeline"
					>
						<div
							className="loader"
							style={{
								position: "absolute",
								zIndex: "100",
							}}
						/>
					</div>
				) : (
					<div id="timeline" />
				)}
			</footer>

			{props.showAddFilterDialog && (
				<AddFilterDialog
					item={selectedItems[0]}
					getItem={getItemFromTrackIndex}
					resources={props.resources.resources}
					project={projectUrl}
					onClose={closeAddFilterDialog}
					onAdd={(filter, secondParameter) => props.onAddFilter(filter, secondParameter)}
					onDel={(filter, sec, third) => props.onDelFilter(filter, sec, third)}
					fetchError={props.fetchError}
					gradientColor={props.gradientColor}
					setgradientColor={props.setgradientColor}
					hexColor={props.hexColor}
					sethexColor={props.sethexColor}
					filterName={filterName}
					openAddFilterDialogEdit={openAddFilterDialogEdit}
					setOpenAddFilterDialogEdit={setOpenAddFilterDialogEdit}
					loading={loading}
					setLoading={setLoading}
					setGlobalBrandColor={setGlobalBrandColor}
					globalBrandColor={globalBrandColor}
				/>
			)}
		</>
	);
}
